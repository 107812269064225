.footer1-wrapper {
  background: @template-bg-color;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: @template-footer-text-color;
  .footer1 {
    .home-page {
      padding: 64px 24px 80px;
    }
  }
  .block {
    padding: 0 32px 12px;
    .logo {
      max-width: 180px;
    }
    .slogan {
      font-size: 10px;
      margin-top: -20px;
    }
    >h2 {
      margin-bottom: 24px;
      color: @template-text-color;
    }
    a {
      color: @template-footer-text-color;
      margin-bottom: 12px;
      float: left;
      clear: both;
      &:hover {
        color: @primary-color;
      }
    }
 .black{
  margin-left: -70px;
 }
  }
  .copyright-wrapper {
    width: 100%;
    border-top: 1px solid fade(@line-color, 10);
    .home-page {
      padding: 0 24px;
      overflow: hidden;
    }
    .copyright {
      height: 80px;
      text-align: center;
      line-height: 80px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer1 {
   
    min-height: 70px;
    font-size: 10px;
    &-wrapper {
      .footer1 {
        .home-page {
          padding: 16px 6px 16px;
        }
      }
    }
    .logo {
      margin: 2px auto 12px;
      width: 80%;
    }
    .block {
      text-align: center;
      margin-bottom: 4px;
      padding: 0;
      .black{
        font-size: 10px;
        margin-left: -70px;
      }
    }
    >ul {
      width: 50%;
      margin: 10px auto 0;
      padding: 5px 0;
      >li {
        width: 50%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    .copyright {
      &-wrapper {
       
        .home-page {
          padding: 0;
          .copyright {
            font-size: 8px;
           
          }
        }
      }

      span {
        width: 90%;
      }
    }
  }
}
