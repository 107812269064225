#Banner0_0.kceo217ocdi-editor_css {
  border-radius: 0%;
}
#Banner0_0 .banner0-text-wrapper > .kceo00knvlt-editor_css {
  font-size: 20px;
  font-weight: bold;
  color: rgb(64, 126, 72);
  transition: all 0s ease 0s, border 0s ease 0s;
}
#Banner1_0 .banner-anim > .banner-anim-elem > .kceoi1j9t1q-editor_css {
}
#Content5_0 .kceomxim1t6-editor_css {
  background: transparent;
  background-attachment: fixed;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Footer1_0 .footer1 > .kceopf87l2-editor_css {
  background-clip: padding-box;
}
#Content4_0 .kceoudgx5v-editor_css {
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: fixed;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
#Banner0_0 .banner0-text-wrapper > .kceo0apni4r-editor_css {
  border-top-left-radius: 20%;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
  border-bottom-left-radius: 20%;
  color: rgba(0, 252, 33, 0);
  text-align: right;
  border-top-color: rgb(251, 251, 251);
  border-right-color: rgb(251, 251, 251);
  border-bottom-color: rgb(251, 251, 251);
  border-left-color: rgb(251, 251, 251);
  border-radius: 25%;
  box-shadow: 2px 3px 5px rgb(251, 251, 251);
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.35);
}
#Pricing1_0 .kcep1f6b6qd-editor_css {
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg');
  background-attachment: fixed;
  background-blend-mode: normal;
  background-position: 0% 0%;
  background-repeat: repeat;
  background-size: auto;
  background-clip: padding-box;
}
